import { Container, Nav, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./style.css";
import { useEffect } from "react";

export default function PrivacyPolicy() {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        try {
            const qsLang = new URLSearchParams(window.location.search).get(
                "lang"
            );
            if (["en", "ka"].includes(qsLang)) {
                i18n.changeLanguage(qsLang);
            } else {
                i18n.changeLanguage("ka");
            }
        } catch (error) {
            console.log(error);
        }
    }, [i18n]);

    return (
        <div>
            <div className="pp-language">
                <Stack direction="horizontal" gap={4}>
                    <Nav.Link
                        className={[
                            "pp-language",
                            i18n.language === "ka" ? "active" : null,
                        ]}
                        onClick={() => i18n.changeLanguage("ka")}
                    >
                        ქართული
                    </Nav.Link>
                    <Nav.Link
                        className={[
                            "pp-language",
                            i18n.language === "en" ? "active" : null,
                        ]}
                        onClick={() => i18n.changeLanguage("en")}
                    >
                        English
                    </Nav.Link>
                </Stack>
            </div>

            <Container>
                <h2 className="text-center">{t("privacy-policy")}</h2>

                <br />

                <p>{t("privacy-policy-page.ltd")}</p>
                <p>{t("privacy-policy-page.id")}</p>
                <p>{t("privacy-policy-page.email")}</p>
                <p>{t("privacy-policy-page.tel")}</p>
                <p>{t("privacy-policy-page.last-updated")}</p>

                <br />

                <h4>{t("privacy-policy-page.consent")}</h4>
                <p>{t("privacy-policy-page.consent-p1")}</p>
                <p>{t("privacy-policy-page.consent-p2")}</p>

                <br />

                <h4>{t("privacy-policy-page.data-processing")}</h4>
                <p>{t("privacy-policy-page.data-processing-p1")}</p>
                <ul>
                    <li>{t("privacy-policy-page.data-processing-b1")}</li>
                    <li>{t("privacy-policy-page.data-processing-b2")}</li>
                    <li>{t("privacy-policy-page.data-processing-b3")}</li>
                </ul>

                <br />

                <h4>{t("privacy-policy-page.purpose-processing-user-data")}</h4>
                <ul>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b1"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b2"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b3"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b4"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b5"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b6"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b7"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b8"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b9"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b10"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b11"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b12"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b13"
                        )}
                    </li>
                    <li>
                        {t(
                            "privacy-policy-page.purpose-processing-user-data-b14"
                        )}
                    </li>
                </ul>

                <br />

                <h4>{t("privacy-policy-page.user-rights")}</h4>
                <p>{t("privacy-policy-page.user-rights-p1")}</p>
                <ul>
                    <li>{t("privacy-policy-page.user-rights-b1")}</li>
                    <li>{t("privacy-policy-page.user-rights-b2")}</li>
                    <li>{t("privacy-policy-page.user-rights-b3")}</li>
                    <li>{t("privacy-policy-page.user-rights-b4")}</li>
                </ul>
                <p>{t("privacy-policy-page.user-rights-p2")}</p>
                <p>{t("privacy-policy-page.user-rights-p3")}</p>

                <br />

                <h4>{t("privacy-policy-page.user-obligation")}</h4>
                <p>{t("privacy-policy-page.user-obligation-p1")}</p>

                <br />

                <h4>{t("privacy-policy-page.terms-of-use")}</h4>
                <p>{t("privacy-policy-page.terms-of-use-p1")}</p>
                <p>{t("privacy-policy-page.terms-of-use-p2")}</p>

                <br />

                <h4>{t("privacy-policy-page.who-we-share-data")}</h4>
                <p>{t("privacy-policy-page.who-we-share-data-p1")}</p>
                <p>{t("privacy-policy-page.who-we-share-data-p2")}</p>
                <p>{t("privacy-policy-page.who-we-share-data-p3")}</p>

                <br />

                <h4>{t("privacy-policy-page.data-acquisition")}</h4>
                <ul>
                    <li>{t("privacy-policy-page.data-acquisition-b1")}</li>
                    <li>{t("privacy-policy-page.data-acquisition-b2")}</li>
                    <li>{t("privacy-policy-page.data-acquisition-b3")}</li>
                </ul>

                <br />

                <h4>{t("privacy-policy-page.what-kind-of-data")}</h4>
                <p>{t("privacy-policy-page.what-kind-of-data-p1")}</p>
                <p>{t("privacy-policy-page.what-kind-of-data-p2")}</p>
                <ul>
                    <li>{t("privacy-policy-page.what-kind-of-data-b1")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b2")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b3")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b4")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b5")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b6")}</li>
                    <li>{t("privacy-policy-page.what-kind-of-data-b7")}</li>
                </ul>
                <p>{t("privacy-policy-page.what-kind-of-data-p3")}</p>

                <br />

                <h4>{t("privacy-policy-page.privacy-policy-change")}</h4>
                <p>{t("privacy-policy-page.privacy-policy-change-p1")}</p>
                <p>{t("privacy-policy-page.privacy-policy-change-p2")}</p>

                <br />

                <h4>{t("privacy-policy-page.contact-us")}</h4>
                <p>{t("privacy-policy-page.contact-us-p1")}</p>
            </Container>
        </div>
    );
}
